<template>
    <div class="container">
        <el-page-header @back="$router.back()" content="个人用户信息"></el-page-header>
        <div class="content" v-if="pageShow">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="100px">
                <div class="basic_info">
                    <div class="head_portrait">
                        <img :src="form.appHeadimgurl || defaultImg" >
                    </div>
                    <div class="basic_info_content">
                        <el-row><el-col><el-form-item label="个人用户ID：" label-width="auto">{{userId}}</el-form-item></el-col></el-row>
                        <el-row>
                            <el-col :span="6" :lg="5">
                                <el-form-item label="昵称：" label-width="auto">{{form.nickname || '无'}}</el-form-item>
                            </el-col>
                            <el-col :span="18" :lg="19">
                                <el-form-item label="手机号：">
                                    <span>{{form.phone || '未绑定'}}</span>
                                    <el-button size="mini" style="margin-left: 15px" type="info" @click="openUpdateUphone">修改</el-button>
                                    <el-button size="mini" type="info" @click="unbind" v-if="form.phone">解绑</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row><el-col>
                            <template v-if="form.memberNo">
                                <el-form-item label="会员号：" label-width="auto">{{form.memberNo}}</el-form-item>
                            </template>
                            <template v-else>
                                <el-form-item label-width="0">实名认证可获取会员号</el-form-item>
                            </template>
                        </el-col></el-row>
                    </div>
                </div>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="真实姓名：" prop="name">
                            <el-input type="text" v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="证件类型：" prop="identityType">
                            <el-select v-model="form.identityType" @change="getIdentityInfo" :disabled="form.memberNo && realIdentityType == 1">
                                <el-option v-for="(item,index) in identityTypeList" :value="item.type" :label="item.name" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="证件号码：" prop="identityNo">
                            <el-input type="text" v-model="form.identityNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="出生日期：" prop="birthday">
                            <el-input v-model="form.birthday" :disabled="form.identityType == 1" placeholder="年月日、年月、年，-分割"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="性别：" prop="gender">
                            <el-select v-model="form.gender" :disabled="form.identityType == 1">
                                <el-option :value="0" label="未知"></el-option>
                                <el-option :value="1" label="男"></el-option>
                                <el-option :value="2" label="女"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="证件照片：">
                            <image-upload
                                :image-url.sync="form.frontImg"
                                :oss-config="ossConfig"
                                :fileSize="0.5 * 1024 * 1024"
                            >
                                <template slot="tip">
                                    <p>只能上传jpg/png格式，大小不超过 0.5MB</p>
                                </template>
                            </image-upload>
                            <image-upload
                                :image-url.sync="form.behindImg"
                                :oss-config="ossConfig"
                                :fileSize="0.5 * 1024 * 1024"
                            >
                                <template slot="tip">
                                    <p>只能上传jpg/png格式，大小不超过 0.5MB</p>
                                </template>
                            </image-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="肖像照片：">
                            <image-upload
                                :image-url.sync="form.portraitImg"
                                :oss-config="ossConfig"
                                :fileSize="1 * 1024 * 1024"
                            >
                                <template slot="tip">
                                    <p>只能上传jpg/png格式，大小不超过 1MB</p>
                                </template>
                            </image-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属机构：">
                            <el-select
                                v-model="form.companyInfo"
                                filterable
                                remote
                                placeholder="请输入机构名称"
                                :remote-method="getCompanyList"
                                :loading="company.loading">
                                <el-option key="无" value="">无</el-option>
                                <el-option key="default" :label="form.companyInfo.split('|')[1]" :value="form.companyInfo" v-if="!company.list.length && form.companyInfo"></el-option>
                                <el-option v-for="(item,index) in company.list" :key="index" :label="item.CompanyName" :value="`${item.CompanyId}|${item.CompanyName}`"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="通讯地址：">
                        <el-row>
                            <el-col :span="4">
                                <el-form-item label="国家：" label-width="60px" prop="country">
                                    <el-input type="text" v-model="form.country"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-if="form.country == '中国'">
                                <el-form-item label="省市区：" label-width="80px">
                                    <el-select 
                                        v-model="form.provinceId" 
                                        @change="getCity" 
                                        placeholder="请选择省"
                                        :loading="province.loading"
                                        style="width:30%;margin-right: 10px">
                                        <el-option v-for="(item,index) in province.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                                    </el-select>
                                    <el-select 
                                        v-model="form.cityId" 
                                        @change="getArea" 
                                        placeholder="请选择市" 
                                        :loading="city.loading"
                                        style="width:30%;margin-right: 10px">
                                        <el-option v-for="(item,index) in city.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                                    </el-select>
                                    <el-select 
                                        v-model="form.areaId" 
                                        placeholder="请选择区县" 
                                        :loading="area.loading"
                                        style="width:30%;">
                                        <el-option v-for="(item,index) in area.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9">
                                <el-form-item label="详细：" label-width="60px" prop="address">
                                    <el-input type="text" v-model="form.address"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="form.country == '中国'">
                                <el-form-item label="邮编：" label-width="70px" prop="postCode">
                                    <el-input type="text" v-model="form.postCode"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="工作单位：" prop="jobUnit">
                            <el-input type="text" v-model="form.jobUnit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="职务：" prop="jobTitle">
                            <el-input type="text" v-model="form.jobTitle"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="文化程度：">
                            <el-select v-model="form.educationLevel">
                                <el-option key="无" value="">无</el-option>
                                <el-option v-for="(item,index) in educationLevelList" :value="item.Value" :label="item.Text" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="毕业院校：" prop="educationInstitution">
                            <el-input type="text" v-model="form.educationInstitution"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="文化程度证明：">
                            <image-upload
                                :image-url.sync="form.educationPhoto"
                                :oss-config="ossConfig"
                                :fileSize="0.5 * 1024 * 1024"
                            >
                                <template slot="tip">
                                    <p>只能上传jpg/png格式，大小不超过 0.5MB</p>
                                </template>
                            </image-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-button type="primary" style="margin-left: 100px" @click="save"> 保存 </el-button>
                </el-row>
            </el-form>
        </div>
        <el-dialog
            title="修改手机号"
            :visible.sync="updatePhone.visible"
            width="420px">
            <el-form :model="updatePhone" ref="updateForm" :rules="rules" label-width="100px">
                <div>
                    <el-form-item label="原手机号：">
                        <el-input disabled :value="updatePhone.oldPhone || '未绑定'" style="max-width: initial">
                            <span slot="suffix">修改成功后将释放占用</span>
                        </el-input>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="新手机号：" prop="phone">
                        <el-input v-model="updatePhone.phone" style="max-width: initial" :maxlength="11">
                            <span slot="suffix">必须是未被占用的手机号</span>
                        </el-input>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="savePhone">保存</el-button>
                <el-button @click="updatePhone.visible = false">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import identityType from '@/utils/identityType.js';
import identityCard from '@/utils/identityCard.js';
import utils from '@/utils';
import imageUpload from '../../components/imageUpload.vue';

export default {
    name: 'UserDetail',
    components:{
        imageUpload
    },
    data() {
        let userId = +this.$route.params.id;
        return {
            pageShow: false,
            userId,
            defaultImg: require('@/assets/imgs/个人@3x.png'),
            form:{
                appHeadimgurl: "",
                nickname: "",
                phone: "",
                name: "",
                gender: 0,
                memberNo: "",
                birthday: "",
                identityType: null,
                identityNo: "",
                frontImg: "",
                behindImg: "",
                portraitImg: "",
                companyInfo: "",
                country: "中国",
                provinceId: "",
                cityId: "",
                areaId: "",
                address: "",
                postCode: "",
                jobUnit: "",
                jobTitle: "",
                educationLevel: null,
                educationInstitution: "",
                educationPhoto: ""
            },
            realIdentityType: null,
            identityTypeList: identityType.list,
            company:{
                loading: false,
                list: []
            },
            province:{
                loading: false,
                list: []
            },
            city:{
                loading: false,
                list: []
            },
            area:{
                loading: false,
                list: []
            },
            educationLevelList: [],
            updatePhone: {
                visible: false,
                oldPhone: "",
                phone: ""
            },
            ossConfig: {}
        };
    },
    created(){
        this.getDetailData();
        this.getOSSConfig();
    },
    computed:{
        rules(){
            return {
                phone: [
                    {required: true, message: '请输入手机号码'},
                    {validator: (rule, value, callback)=>{
                        if(value.length != 11 || value[0] !== '1'){
                            callback(new Error('手机号格式不正确'));
                        }else{
                            callback();
                        }
                    }}
                ],
                name: [
                    {min: 2, max: 50, message: '限制2～50个字符'},
                    {validator: (rule, value, callback)=>{
                        if(this.form.memberNo && !value){
                            callback(new Error('已实名，不能为空'));
                        }else{
                            callback();
                        }
                    }}
                ],
                identityType: [
                    {validator: (rule, value, callback)=>{
                        if(this.form.memberNo && !value){
                            callback(new Error('已实名，不能为空'));
                        }else{
                            callback();
                        }
                    }}
                ],
                identityNo: [{
                    validator: (rule, value, callback)=>{
                        if(this.form.memberNo && !value){
                            callback(new Error('已实名，不能为空'));
                        }else if(value){
                            let type = this.form.identityType;
                            if(!type){
                                callback(new Error('请先选择证件类型'));
                            }else{
                                if(type == 1){
                                    let info = identityCard(value);
                                    if(info){
                                        let min = new Date('1900-01-01').getTime(),
                                            max = new Date().getTime(),
                                            current = new Date(info.dateBirth).getTime();
                                        if(current < min || current > max){
                                            callback(new Error('证件号出生日期不是有效日期'));
                                        }else{
                                            this.form.gender = info.sex;
                                            this.form.birthday = utils.dateFormat(new Date(info.dateBirth),'yyyy-MM-dd');
                                            callback();
                                        }
                                    }else{
                                        callback(new Error('证件号格式有误'));
                                    }
                                }else if(!value.match(this.cardInfo.RegEx)){
                                    callback(new Error('证件号格式有误'));
                                }else{
                                    callback();
                                }
                            }
                        }else{
                            callback();
                        }
                    }
                }],
                birthday: [{
                    validator: (rule, value, callback)=>{
                         if(this.form.memberNo){
                            let dateStr = utils.dateFormat(value);
                            if(!value){
                                callback(new Error('已实名，不能为空'));
                            }else if(!(dateStr != 'Invalid Date' && /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/.test(value))){
                                callback(new Error('已实名，请输入有效的完整年月日'));
                            }else{
                                callback();
                            }
                        }else if(value){
                            let current = utils.abnormalDate.get(value,'yyyy-MM-dd');
                            if(current && current != 'Invalid Date'){
                                let min = new Date('1900-01-01').getTime(),
                                    max = new Date().getTime();
                                current = new Date(current).getTime();
                                if(current < min || current > max){
                                    callback(new Error('不是有效日期'));
                                }else{
                                    callback();
                                }
                            }else{
                                callback(new Error('不是有效日期'));
                            }
                        }else{
                            callback();
                        }
                    }
                }],
                gender: [
                    {validator: (rule, value, callback)=>{
                        if(this.form.memberNo && !value){
                            callback(new Error('已实名，不能为空'));
                        }else{
                            callback();
                        }
                    }}
                ],
                country: [{min: 2, max: 20, message: '限制2～20个字符'}],
                address: [{min: 5, max: 50, message: '限制5～50个字符'}],
                postCode: [{validator: (rule, value, callback)=>{
                        if(value){
                            if(value.length != 6 || !Number.isInteger(+value)){
                                callback(new Error('请输入6位数字'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }
                }],
                jobUnit: [{min: 3, max: 50, message: '限制3～50个字符'}],
                jobTitle: [{min: 2, max: 20, message: '限制2～20个字符'}],
                educationInstitution: [{min: 3, max: 50, message: '限制3～50个字符'}]
            } 
        },
        cardInfo(){
            return identityType.getInfo(this.form.identityType);
        }
    },
    methods: {
        getOSSConfig(){
            this.$api.GetPersonalUserUploadOptions({
                "QueryUserId": this.userId
            }).then(data=>{
                if(data.Code == 0){
                    this.ossConfig = data.UploadOption;
                }
            })
        },
        getDetailData(){
            var loading = this.$loading({
                text: '数据读取中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.GetUserAccountDetail({
                "QueryUserId": this.userId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.pageShow = true;
                    let userInfo = data.UserInfo;
                    let birthday = utils.abnormalDate.get(userInfo.Birthday2, 'yyyy-MM-dd');
                    let Region = userInfo.AddressInfo.Region || {};
                    let companyInfo = (userInfo.CompanyId && userInfo.CompanyName) ? `${userInfo.CompanyId}|${userInfo.CompanyName}` : '';
                    this.form = {
                        appHeadimgurl: userInfo.AvatarUrl,
                        nickname: userInfo.Nickname,
                        phone: userInfo.Phone,
                        name: userInfo.Name,
                        gender: userInfo.Gender,
                        memberNo: userInfo.MemberNo,
                        birthday,
                        identityType: userInfo.IdentityType || null,
                        identityNo: userInfo.IdentityNo,
                        frontImg: userInfo.IdentityPhoto1,
                        behindImg: userInfo.IdentityPhoto2,
                        portraitImg: userInfo.PortraitPhoto,
                        companyInfo,
                        country: userInfo.AddressInfo.Country,
                        provinceId: Region.ProvinceCode || "",
                        cityId: Region.CityCode || "",
                        areaId: Region.AreaCode || "",
                        address: userInfo.AddressInfo.Address,
                        postCode: userInfo.AddressInfo.PostCode,
                        jobUnit: userInfo.JobUnit,
                        jobTitle: userInfo.JobTitle,
                        educationLevel: userInfo.EducationLevel,
                        educationInstitution: userInfo.EducationInstitution,
                        educationPhoto: userInfo.EducationPhoto
                    };
                    this.realIdentityType = userInfo.IdentityType || null;
                    this.getProvince(true);
                    this.getCity(true);
                    this.getArea(true);
                    this.getEducation();
                }
            })
        },
        openUpdateUphone(){
            this.updatePhone = {
                visible: true,
                oldPhone: this.form.phone,
                newPhone: ""
            }
            this.$nextTick(function(){
                this.$refs.updateForm.clearValidate();
            });
        },
        unbind(){
            this.$confirm('确定要解除绑定的手机号吗？', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.ClearUserAccountPhone({
                    "QueryUserId": this.userId
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('解除成功');
                        this.form.phone = "";
                    }
                })
            })
        },
        getIdentityInfo(){
            let form = this.form;
            if(form.identityType == 1){
                let info = identityCard(form.identityNo);
                if(info){
                    let min = new Date('1900-01-01').getTime(),
                        max = new Date().getTime(),
                        current = new Date(info.dateBirth).getTime();
                    if(current > min && current < max){
                        form.gender = info.sex;
                        form.birthday = utils.dateFormat(new Date(info.dateBirth),'yyyy-MM-dd');
                    }
                }
            }
        },
        getCompanyList(value){
            if(value){
                this.company.loading = true;
                this.$api.GetCompanyPageList({
                    "Continuation": {
                        "LastId": 0,
                        "MaxCount": 10
                    },
                    "CompanyName": value,
                    "AuditStatus": null
                }).then(data=>{
                    this.company.loading = false;
                    if(data.Code == 0){
                        this.company.list = data.ContinueList || [];
                    }
                })
            }
        },
        savePhone(){
            this.$refs.updateForm.validate(valid=>{
                if(valid){
                    this.$api.AlterUserAccountPhone({
                        "QueryUserId": this.userId,
                        "Phone": this.updatePhone.phone
                    }).then(data=>{
                        if(data.Code == 0){
                            this.$message.success('修改成功');
                            this.form.phone = this.updatePhone.phone;
                            this.updatePhone.visible = false;
                        }
                    })
                }
            })
        },
        save(){
            this.$refs.form.validate(valid=>{
                if(valid){
                    let loading = this.$loading({
                        text: '保存中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.8)'
                    })
                    let form = this.form;
                    let birthday = utils.abnormalDate.set(form.birthday);
                    let companyId = 0, companyName = "";
                    if(form.companyInfo){
                        let info = form.companyInfo.split('|');
                        companyId = +info[0];
                        companyName = info[2]
                    }
                    let addressInfo = {};
                    if(form.country == "中国"){
                        let provinceList = this.province.list,
                            cityList = this.city.list,
                            areaList = this.area.list;
                        let regionName = [];
                        if(form.provinceId > 0){
                            regionName.push((provinceList.filter(item=>item.Code == form.provinceId)[0] || {}).Name);
                        }
                        if(form.cityId > 0){
                            regionName.push((cityList.filter(item=>item.Code == form.cityId)[0] || {}).Name);
                        }
                        if(form.areaId > 0){
                            regionName.push((areaList.filter(item=>item.Code == form.areaId)[0] || {}).Name);
                        }
                        regionName = [...new Set(regionName)];
                        addressInfo = {
                            "Country": form.country,
                            "Region": {
                                "ProvinceCode": form.provinceId || 0,
                                "CityCode": form.cityId || 0,
                                "AreaCode": form.areaId || 0,
                                "RegionName": regionName.join('')
                            },
                            "Address": form.address,
                            "PostCode": form.postCode
                        }
                    }else{
                        addressInfo = {
                            "Country": form.country || '中国',
                            "Region": {
                                "ProvinceCode": 0,
                                "CityCode": 0,
                                "AreaCode": 0,
                                "RegionName": ""
                            },
                            "Address": "",
                            "PostCode": ""
                        }
                    }
                    
                    this.$api.SaveUserAccountDetail({
                        "UserInfo": {
                            "UserId": this.userId,
                            "Phone": form.Phone,
                            "Nickname": form.nickname,
                            "AvatarUrl": form.appHeadimgurl,
                            "Name": form.name,
                            "Gender": form.gender,
                            "Birthday2": birthday,
                            "IdentityType": form.identityType,
                            "IdentityNo": form.identityNo,
                            "MemberNo": form.memberNo,
                            "IdentityPhoto1": form.frontImg,
                            "IdentityPhoto2": form.behindImg,
                            "PortraitPhoto": form.portraitImg,
                            "CompanyId": companyId,
                            "CompanyName": companyName,
                            "AddressInfo": addressInfo,
                            "JobUnit": form.jobUnit,
                            "JobTitle": form.jobTitle,
                            "EducationLevel": form.educationLevel,
                            "EducationInstitution": form.educationInstitution,
                            "EducationPhoto": form.educationPhoto
                        }
                    }).then(data=>{
                        loading.close();
                        if(data.Code == 0){
                            this.$message.success('保存成功');
                            this.getDetailData();
                        }
                    })
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        //获取省
        getProvince(isInit) {
            this.province.loading = true;
            this.$api.GetProvinceList().then(data => {
                this.province.loading = false;
                if(data.Code == 0){
                    let list = data.ProvinceList || [];
                    this.province.list = list;
                    if(isInit != true){
                        this.city.list = [];
                        this.area.list = [];
                        this.form.provinceId = "";
                        this.form.cityId = "";
                        this.form.areaId = "";
                    }
                }
            })
        },
        //获取市
        getCity(isInit) {
            if(!this.form.provinceId)return;
            this.city.loading = true;
            this.$api.GetCityList({
                "ProvinceCode": this.form.provinceId
            }).then(data => {
                this.city.loading = false;
                if(data.Code == 0){
                    let list = data.CityList || [];
                    this.city.list = list;
                    if(isInit != true){
                        this.area.list = [];
                        this.form.cityId = "";
                        this.form.areaId = "";
                    }
                }
            })
        },
        //获取区县
        getArea(isInit) {
            if(!this.form.cityId)return;
            this.area.loading = true;
            this.$api.GetAreaList({
                "CityCode": this.form.cityId
            }).then(data => {
                this.area.loading = false;
                if(data.Code == 0){
                    let list = data.AreaList || [];
                    this.area.list = list;
                    if(isInit != true){
                        this.form.areaId = "";
                    }
                }
            })
        },
        //获取学历列表
        getEducation(){
            this.$api.GetDictionaryItemList({
                "DictionaryKey": "education_level"
            }).then(data=>{
                if(data.Code == 0){
                    this.educationLevelList = data.ItemList;
                }
            })
        }
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.basic_info{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}
.basic_info .head_portrait{
    margin-right: 15px;
}
.basic_info .head_portrait,
.basic_info .head_portrait img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
}
.basic_info_content{
    width: calc(100% - 95px);
}
.basic_info_content .el-form-item{
    margin: 0 !important;
}
</style>