<template>
    <div class="container">
        <el-page-header @back="$router.back()" :content="['创建培训','编辑培训信息'][this.type]"></el-page-header>
        <div class="content">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="100px">
                <el-form-item label="运动项目：" prop="eventsId">
                    <el-select v-model="form.eventsId" @change="eventsChange">
                        <el-option v-for="(item,index) in eventsList" :value="item.Id" :label="item.Name" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="培训类型：" prop="trainType">
                    <el-radio-group v-model="form.trainType" @change="trainTypeChange">
                        <el-radio :label="1">裁判员培训</el-radio>
                        <el-radio :label="2">教练员培训</el-radio>
                        <el-radio :label="3">裁判员和教练员培训</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="培训全称：" prop="trainFullName">
                    <el-input v-model="form.trainFullName" style="max-width:500px"></el-input>
                </el-form-item>
                <el-form-item label="培训简称：" prop="name">
                    <el-input v-model="form.name" style="max-width:500px"></el-input>
                </el-form-item>
                <el-form-item label="培训形式：" prop="trainForm">
                    <el-radio-group v-model="form.trainForm">
                        <el-radio :label="1">线上培训</el-radio>
                        <el-radio :label="2">线下培训</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="培训封面：">
                    <image-upload
                        :image-url.sync="form.cover"
                        :oss-config="ossConfig"
                        :fileSize="1 * 1024 * 1024"
                    >
                        <template slot="tip">
                            <p>只能上传jpg/png格式，长宽比例：375×211，大小不超过 1MB，</p>
                            <p>建议大小 0.5MB，分辨率：1080×608 或 1440×810</p>
                        </template>
                    </image-upload>
                </el-form-item>
                <el-form-item label="培训时间：" prop="trainTime">
                    <el-date-picker
                        v-model="form.trainTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-ddTHH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-card shadow="never" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                    <el-form-item label="培训地点:" prop="address" :required="this.form.trainForm == 2" style="margin-bottom:0;">
                        <el-row>
                            <el-select 
                                v-model="form.provinceCode" 
                                @change="getCity" 
                                placeholder="请选择省"
                                :loading="province.loading"
                                style="width: calc((100% - 20px) / 3);max-width: 200px;margin-right: 10px">
                                <el-option v-for="(item,index) in province.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                            </el-select>
                            <el-select 
                                v-model="form.cityCode" 
                                @change="getArea" 
                                placeholder="请选择市" 
                                :loading="city.loading"
                                style="width: calc((100% - 20px) / 3);max-width: 200px;margin-right: 10px">
                                <el-option v-for="(item,index) in city.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                            </el-select>
                            <el-select 
                                v-model="form.areaCode" 
                                placeholder="请选择区县" 
                                :loading="area.loading"
                                style="width: calc((100% - 20px) / 3);max-width: 200px">
                                <el-option v-for="(item,index) in area.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                            </el-select>
                        </el-row>
                        <el-row style="margin-top: 10px">
                            <el-input v-model="form.address" placeholder="详细地址" style="max-width: initial"></el-input>
                        </el-row>
                    </el-form-item>
                </el-card>
                <el-card shadow="never" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                    <el-tree 
                        :data="units" 
                        node-key="Kind" 
                        draggable
                        :allow-drop="handleAllowDrop" 
                        @node-drop="nodeDrop"
                    >
                        <template slot-scope="scope">
                            <el-form-item :label="`${scope.node.data.Kind}:`" :prop="`units['${scope.node.data.Kind}']`" :rules="getUnitRules(scope.node.data.Kind)" label-width="100px" style="margin-bottom: 0">
                                <div style="width:100%;max-width:500px;white-space: normal;">
                                    <el-tag
                                        v-for="(unitItem,unitIndex) in scope.node.data.Units" :key="unitIndex"
                                        disable-transitions effect="plain" closable
                                        @close="removeUnit(scope.node.data.Kind, unitIndex)" 
                                    >
                                        <span>{{unitItem}}</span>
                                    </el-tag>
                                </div>
                            </el-form-item>
                        </template>
                    </el-tree>
                    <el-form-item label-width="130px" style="margin-top:20px;margin-bottom:0">
                        <template slot="label">
                            <el-dropdown @command="unitTypeChange" :disabled="!visibleUnitTypes">
                                <el-input v-model="unitType" placeholder="自定义名称" style="width: 100px" :maxlength="4" @focus="visibleUnitTypes = false" @blur="visibleUnitTypes = true"></el-input>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in unitTypes" :key="item" :command="item">{{item}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>:
                        </template>
                        <el-input v-model.trim="unitName" :placeholder="unitType + '名称'" style="width:calc(100% - 90px);max-width:400px"></el-input>
                        <el-button type="primary" style="margin-left:10px" @click="addUnit()"  :title="'点击添加' + unitType"><i class="el-icon-plus"></i> 添加</el-button>
                    </el-form-item>
                </el-card>
                <el-form-item label="培训详情:" prop="detail">
                    <Editor v-model="form.detail" :oss-options="ossConfig"></Editor>
                </el-form-item>
                <el-divider></el-divider>
                <div style="margin-bottom:20px">培训内容</div>
                <template v-if="form.eventsId">
                    <el-card shadow="never" v-for="(item, index) in classEvents" :key="item.Id" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                        <el-form-item label="培训项目:" label-width="100px" style="margin-bottom:10px">
                            <el-row type="flex" justify="space-between">
                                <span>{{item.Name}}</span>
                                <el-button type="danger" @click="removeClassEvents(index)" v-show="classEvents.length > 1"><i class="el-icon-minus"></i> 删除此项目</el-button>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="已添加:" label-width="100px" style="width:100%;" prop="classList">
                            <el-tag
                                v-for="(classItem,classIndex) in handleClassList[item.Id]" :key="classIndex"
                                disable-transitions effect="plain" closable style="cursor: pointer;"
                                @click="openClass(item, classItem)"
                                @close="removeClass(classItem)"
                            >
                                <span>{{classItem.ClassName}}</span>
                            </el-tag>
                        </el-form-item>
                        <el-button type="primary" size="small" @click="openClass(item)" style="margin-left:100px" title="点击添加"><i class="el-icon-plus"></i> 添加</el-button>
                    </el-card>
                    <el-dropdown @command="addClassEvents" v-if="handleEventsList.length > classEvents.length">
                        <el-button type="primary" size="medium" style="width:150px"><i class="el-icon-plus"></i> 添加培训项目</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in handleEventsList" :key="item.Id" :command="item" :disabled="classEvents.some(eventsItem=>eventsItem.Id == item.Id)">{{item.Name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px">
                <el-button type="success" style="width:100px" @click="save()">保存</el-button>
            </el-row>
        </div>
        <el-dialog :title="classInfo.classInfo ? '修改培训内容' : '添加培训内容'" :visible.sync="classInfoVisible" :close-on-click-modal="false" :destroy-on-close="true" width="500px" >
            <el-form ref="classForm" :model="classForm" :rules="classRules" label-width="100px" size="small">
                <el-form-item label="名称:" prop="name">
                    <el-input v-model.trim="classForm.name" maxlength="20" placeholder="请输入名称" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="类型:" prop="type">
                    <el-radio-group v-model="classForm.type" :disabled="form.trainType != 3" @change="classTypeChange">
                        <el-radio :label="1" v-if="[1,3].includes(form.trainType)">裁判员培训</el-radio>
                        <el-radio :label="2" v-if="[2,3].includes(form.trainType)">教练员培训</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="级别:" prop="levelNo" >
                    <el-select v-model="classForm.levelNo" :disabled="!classForm.type"  placeholder="请选择级别">
                        <el-option v-for="(item,index) in handleLevelList" :value="item.LevelNo" :label="item.LevelName" :key="index"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="classInfoVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitGroup">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import imageUpload from '@/components/imageUpload.vue';
import Editor from '@/components/Editor';
const defaultUnitTypes = ['主办单位', '指导单位', '支持单位', '承办单位', '运营单位', '协办单位', '赞助单位'];
const requiredUnits = ['主办单位', '承办单位'];
export default {
    name: 'trainingManagementDetail',
    components: {
        imageUpload,
        Editor
    },
    data() {
        let trainId = +this.$route.params.id;
        return {
            trainId,
            type: trainId == 0 ? 0 : 1,
            form: {
                eventsId: null,
                trainType: 3,
                trainFullName: "",
                name: "",
                trainForm: 2,
                cover: "",
                trainTime: [],
                provinceCode: null,
                cityCode: null,
                areaCode: null,
                address: "",
                detail:""
            },
            eventsList: [],
            units: [],
            unitTypes: [...defaultUnitTypes],
            unitType: defaultUnitTypes[0],
            unitName: '',
            visibleUnitTypes: true,
            classEvents: [],
            classList: [],
            classInfoVisible: false,
            classInfo: {},
            classForm:{
                name: '',
                type: '',
                levelNo: ''
            },
            levelObjs: {},
            province:{
                loading: false,
                list: []
            },
            city:{
                loading: false,
                list: []
            },
            area:{
                loading: false,
                list: []
            },
            ossConfig: {}
        };
    },

    created() {
        Promise.all([
            this.$api.GetFlattedEventsList({
                'AllName': null
            }),
            this.$api.GetTrainManageInit({
                "TrainId": this.trainId
            })
        ]).then(results=>{
            if(results.every(data=>data.Code == 0)){
                this.eventsList = results[0].EventsList || [];
                this.ossConfig = results[1].UploadOptions;
                if(this.type){
                    this.getDetailData();
                }else{
                    this.trainId = results[1].NextTrainId;
                }
            }
        })
        
        if(!this.type){
            this.getProvince();
        }
        requiredUnits.forEach(item=>{
            this.units.push({
                Kind: item,
                Units: []
            })
        })
    },
    computed:{
        rules(){
            return {
                eventsId: [{required:true,message:"请选择培训项目"}],
                trainType: [{required:true,message:"请选择培训类型"}],
                trainFullName: [
                    {required:true,message:"请输入培训全称",},
                    {max: 50,message:"最大 50 个字符"}
                ],
                name: [{max: 32,message:"最大 32 个字符"}],
                trainForm: [{required:true,message:"请选择培训形式"}],
                trainTime: [
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0 || !value[0] || !value[1]){
                            callback(new Error('请选择培训时间'));
                        }else{
                            callback();
                        }
                    }}
                ],
                address: [
                    {max: 50,message:"最大 50 个字符"},
                    {validator: (rule, value, callback)=>{
                        let form = this.form;
                        if(form.trainForm == 2){
                            if(!form.provinceCode || !form.cityCode || !form.areaCode){
                                callback(new Error('请选择培训地点'));
                            }else if(!form.address){
                                callback(new Error('请输入详细地址'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }
                }],
                detail: {required:true,message:"请输入培训详情"},
                classList: [
                    {required: true, validator:(rule, value, callback)=>{
                        if(!this.classList || this.classList.length == 0){
                            callback('请添加培训内容');
                        }else{
                            callback();
                        }
                    }
                }]
            }
        },
        classRules(){
            return {
                name:[
                    {required:true,message:"请输入名称"},
                    {max:20,message:"名称最长20个字符"}
                ],
                type:[
                    {required:true,message:"请选择类型"}
                ],
                levelNo: [{required:true,message:"请选择级别"}]
            }
        },
        handleEventsList(){
            let list = [];
            if([3, 8].includes(this.form.eventsId)){
                list = this.eventsList.filter(item=>item.Id.toString()[0] == this.form.eventsId);
            }else{
                list = this.eventsList.filter(item=>this.form.eventsId == item.Id);
            }
            return list;
        },
        handleClassList(){
            let obj = {};
            this.classEvents.forEach(item=>{
                let list = this.classList.filter(classItem=>classItem.EventsId === item.Id);
                obj[item.Id] = list;
            })
            return obj;
        },
        handleLevelList(){
            if(!this.classInfo.eventsInfo || !this.classForm.type){
                return [];
            }
            return this.getLevel(this.classInfo.eventsInfo.Id, this.classForm.type);
        }
    },
    methods: {
        getDetailData(){
            var loading = this.$loading({
                text: '数据读取中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.GetTrainDetail({
                "TrainId": this.trainId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    let detail = data.TrainDetail
                    let form = this.form;
                    let {
                        EventsId,
                        TrainType,
                        TrainForm,
                        TrainFullName,
                        TrainCover
                    } = detail.TrainDescription;
                    form.eventsId = EventsId;
                    form.trainType = TrainType;
                    form.trainFullName = TrainFullName;
                    form.name = detail.TrainName;
                    form.trainForm = TrainForm;
                    form.cover = TrainCover;
                    let {
                        BeginTime,
                        EndTime
                    } = detail.TrainTime;
                    form.trainTime = [BeginTime, EndTime];
                    let {
                        Region,
                        Address
                    } = detail.TrainPlace;
                    form.provinceCode = Region.ProvinceCode;
                    form.cityCode = Region.CityCode;
                    form.areaCode = Region.AreaCode;
                    form.address = Address;
                    form.detail = detail.Descs;
                    if(detail.AllUnits){
                        let units = [],
                            unitTypes = this.unitTypes,
                            unitType = unitTypes[0];
                        detail.AllUnits.forEach(item=>{
                            units.push({
                                Kind: item.Kind,
                                Units: [...item.Units]
                            })
                            if(!defaultUnitTypes.includes(item.Kind)){
                                unitTypes.push(item.Kind);
                            }
                        })
                        if(units.length){
                            unitType = units[0].Kind;
                        }
                        this.units = units;
                        this.unitTypes = unitTypes;
                        this.unitType = unitType;
                    }
                    if(detail.TrainClass){
                        this.classList = [...detail.TrainClass];
                        let classEvents = [];
                        detail.TrainClass.forEach(item=>{
                            if(!classEvents.some(eventsItem=>eventsItem.Id == item.EventsId)){
                                classEvents.push({
                                    Id: item.EventsId,
                                    Name: item.EventsName
                                })
                            }
                        })
                        this.classEvents = classEvents;
                    }else{
                        this.$nextTick(function(){
                            this.classEvents = [this.handleEventsList[0]];
                        });
                    }
                    this.getProvince(true);
                    this.getCity(true);
                    this.getArea(true);
                }
            }).catch(()=>{
                loading.close();
            })
        },
        eventsChange(){
            this.classList = [];
            this.$nextTick(function(){
                this.classEvents = [this.handleEventsList[0]];
                this.$refs.form.clearValidate('classList');
            });
        },
        trainTypeChange(){
            this.classList = [];
            this.$nextTick(function(){
                this.$refs.form.clearValidate('classList');
            });
        },
        nodeDrop(draggingNode, dropNode){
            let arr = [...this.unitTypes];
            let dragData = draggingNode.data,
                dropData = dropNode.data;
            arr.splice(dragData.index, 1);
            arr.splice(dropData.index, 0, dragData.label);
            this.unitTypes = arr;
        },
        handleAllowDrop(draggingNode, dropNode, type){
            if(type == 'inner') return false;
            return true;
        },
        unitTypeChange(type){
            this.unitType = type;
        },
        addUnit(){
            let unitType = this.unitType;
            if(this.unitName && unitType){
                let index = this.units.findIndex(item=>item.Kind == unitType);
                if(index >= 0){
                    let obj = this.units[index];
                    obj.Units.push(this.unitName);
                    this.$set(this.units, index, obj);
                }else{
                    this.units.push({
                        Kind: unitType,
                        Units: [this.unitName]
                    })
                }
                this.unitName = '';
                if(!this.unitTypes.includes(unitType)){
                    this.unitTypes.push(unitType);
                }
                if(requiredUnits.includes(unitType)){
                    this.$refs.form.validateField(`units['${unitType}']`);
                }
            }
        },
        removeUnit(unitType, index){
            let typeIndex = this.units.findIndex(item=>item.Kind == unitType);
            let obj = this.units[typeIndex];
            obj.Units.splice(index, 1);
            if(obj.Units.length == 0 && !requiredUnits.includes(unitType)){
                this.units.splice(typeIndex, 1);
            }else{
                this.$set(this.units, typeIndex, obj);
            }
            if(requiredUnits.includes(unitType)){
                this.$refs.form.validateField(`units['${unitType}']`);
            }
        },
        addClassEvents(item){
            this.classEvents.push(item);
        },
        removeClassEvents(index){
            let obj = this.classEvents[index];
            let list = this.classList.filter(item=>item.EventsId == obj.Id);
            if(!list || list.length == 0){
                this.classEvents.splice(index, 1);
                this.$nextTick(function(){
                    this.$refs.form.validateField('classList');
                })
                return;
            }
            this.$confirm('请确认删除此项目').then(() => {
                this.classEvents.splice(index, 1);
                let list = this.classList.filter(item=>item.EventsId != obj.Id);
                this.classList = list;
                this.$nextTick(function(){
                    this.$refs.form.validateField('classList');
                })
            })
        },
        openClass(item, classItem){
            this.classInfo = {
                eventsInfo: item,
                classInfo: classItem
            }
            if(classItem){
                this.classForm = {
                    name: classItem.ClassName,
                    type: classItem.ClassType,
                    levelNo: classItem.ClassLevel
                }
            }else{
                this.classForm = {
                    name: '',
                    type: this.form.trainType == 2 ? 2 : 1,
                    levelNo: ''
                }
            }
            if([1, 3].includes(this.form.trainType)){
                this.getRefereeLevel(item.Id);
            }
            if([2, 3].includes(this.form.trainType)){
                this.getCoachLevel(item.Id);
            }
            this.$nextTick(function(){
                this.$refs.classForm.clearValidate();
            })
            this.classInfoVisible = true;
        },
        classTypeChange(){
            this.classForm.levelNo = '';
            this.$nextTick(function(){
                this.$refs.classForm.clearValidate('levelNo');
            })
        },
        submitGroup(){
            this.$refs.classForm.validate((valid)=>{
                if(valid){
                    let form = this.classForm;
                    if(this.classInfo.classInfo){
                        let {
                            Id
                        } = this.classInfo.eventsInfo;
                        let {
                            ClassId,
                            extraId
                        } = this.classInfo.classInfo;
                        let index = this.classList.findIndex(item=>(item.ClassId && item.ClassId == ClassId) || (item.extraId && item.extraId == extraId));
                        let levelObj = this.getLevel(Id, form.type).find(item=>item.LevelNo == form.levelNo) || {};
                        this.$set(this.classList, index, {
                            ...this.classInfo.classInfo,
                            ClassName: form.name,
                            ClassType: form.type,
                            ClassLevel: form.levelNo,
                            LevelName: levelObj.LevelName
                        })
                        this.$message.success('修改成功');
                    }else{
                        let {
                            Id,
                            Name
                        } = this.classInfo.eventsInfo;
                        let levelObj = this.getLevel(Id, form.type).find(item=>item.LevelNo == form.levelNo) || {};
                        this.classList.push({
                            "ClassId": 0,
                            "ClassName": form.name,
                            "EventsId": Id,
                            "EventsName": Name,
                            "TrainId": this.trainId,
                            "ClassType": form.type,
                            "ClassLevel": form.levelNo,
                            "LevelName": levelObj.LevelName,
                            extraId: Date.now()
                        })
                        this.$refs.form.validateField('classList');
                        this.$message.success('添加成功');
                    }
                    this.classInfoVisible = false;
                }
            })
        },
        removeClass(classItem){
            let index = this.classList.findIndex(item=>(item.ClassId && item.ClassId == classItem.ClassId) || (item.extraId && item.extraId == classItem.extraId));
            this.classList.splice(index, 1);
            this.$refs.form.validateField('classList');
        },
        getLevel(eventsId, type){
            let key = `${eventsId}-${type}`;
            return this.levelObjs[key] || [];
        },
        getRefereeLevel(eventsId){
            let key = `${eventsId}-1`;
            if(this.levelObjs[key] && this.levelObjs[key].length > 0) return;
            this.$api.GetRefereeLevelDictionary({
                "EventsId": eventsId
            }).then(data=>{
                if(data.Code == 0){
                    this.$set(this.levelObjs, key, data.Levels);
                }
            });
        },
        getCoachLevel(eventsId){
            let key = `${eventsId}-2`;
            if(this.levelObjs[key] && this.levelObjs[key].length > 0) return;
            this.$api.GetCoachLevelDictionary({
                "EventsId": eventsId
            }).then(data=>{
                if(data.Code == 0){
                    this.$set(this.levelObjs, key, data.Levels);
                }
            });
        },
        //获取省
        getProvince(isInit) {
            this.province.loading = true;
            this.$api.GetProvinceList().then(data => {
                this.province.loading = false;
                if(data.Code == 0){
                    let list = data.ProvinceList || [];
                    this.province.list = list;
                    if(isInit != true){
                        this.city.list = [];
                        this.area.list = [];
                        this.form.provinceCode = null;
                        this.form.cityCode = null;
                        this.form.areaCode = null;
                    }
                }
            })
        },
        //获取市
        getCity(isInit) {
            if(!this.form.provinceCode)return;
            this.city.loading = true;
            this.$api.GetCityList({
                "ProvinceCode": this.form.provinceCode
            }).then(data => {
                this.city.loading = false;
                if(data.Code == 0){
                    let list = data.CityList || [];
                    this.city.list = list;
                    if(isInit != true){
                        this.area.list = [];
                        this.form.cityCode = null;
                        this.form.areaCode = null;
                    }
                }
            })
        },
        //获取区县
        getArea(isInit) {
            if(!this.form.cityCode)return;
            this.area.loading = true;
            this.$api.GetAreaList({
                "CityCode": this.form.cityCode
            }).then(data => {
                this.area.loading = false;
                if(data.Code == 0){
                    let list = data.AreaList || [];
                    this.area.list = list;
                    if(isInit != true){
                        this.form.areaCode = null;
                    }
                }
            })
        },
        save(){
            if(this.unitName && this.unitType){
                this.addUnit();
            }
            this.$refs.form.validate((valid)=>{
                if(valid){
                    let trainId = this.trainId;
                    let form = this.form;
                    let eventsList = this.eventsList;
                    let classList = [];
                    this.classEvents.forEach(item=>{
                        let list = this.classList.filter(classItem=>classItem.EventsId === item.Id);
                        classList.push(...list);
                    })
                    classList = classList.map(({extraId, ...info})=>info);
                    let eventsId = form.eventsId;
                    let eventsName = eventsList.filter(item=>item.Id == eventsId)[0].Name;
                    let RegionName = "";
                    RegionName += (this.province.list.filter(item=>item.Code == form.provinceCode)[0] || {}).Name || '';
                    RegionName += (this.city.list.filter(item=>item.Code == form.cityCode)[0] || {}).Name || '';
                    RegionName += (this.area.list.filter(item=>item.Code == form.areaCode)[0] || {}).Name || '';
                    let Units = this.units.filter(item=>item.Units && item.Units.length);
                    let sendData = {
                        "EditInfo": {
                            "TrainId": trainId,
                            "TrainDescription": {
                                "Name": form.name || form.trainFullName,
                                "EventsId": eventsId,
                                "TrainType": form.trainType,
                                "TrainForm": form.trainForm,
                                "TrainFullName": form.trainFullName,
                                "TrainCover": form.cover,
                                "TrainCoverThumbnail": "",
                                "EventsName": eventsName
                            },
                            "TrainName": form.name,
                            "TrainLogo": "",
                            "TrainTime": {
                                "BeginTime": form.trainTime[0],
                                "EndTime": form.trainTime[1]
                            },
                            "TrainPlace": {
                                "Country": "",
                                "Region": {
                                    "ProvinceCode": form.provinceCode,
                                    "CityCode": form.cityCode,
                                    "AreaCode": form.areaCode,
                                    "RegionName": RegionName
                                },
                                "Address": form.address,
                                "PostCode": ""
                            },
                            "AllUnits": Units,
                            "Descs": form.detail,
                            "TrainClass": classList
                        }
                    }
                    let loading = this.$loading({
                        text:"保存中",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    if(this.type == 0){
                        this.$api.CreateTrain(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('培训创建成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }else{
                        this.$api.AlterTrain(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('培训修改成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        getUnitRules(unitType){
            let units = this.units;
            return requiredUnits.includes(unitType) ? [
                {required: true, validator:(rule, value, callback)=>{
                    let obj = units.find(item=>item.Kind == unitType);
                    let list = obj.Units || [];
                    if(list.length == 0){
                        callback('请添加' + unitType);
                    }else{
                        callback();
                    }
                }}
            ] : null;
        },
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
    .el-tree::v-deep .el-tree-node__content{
        height: auto;
    }
    .el-tree::v-deep .el-form-item{
        margin-bottom: 0;
    }
</style>
<style >
    
.unit-popper li{
    padding: 0 10px;
}
</style>
